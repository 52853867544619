import React from "react";
import { allGenres } from "../../../app/utils/utils";
import GenreSelectionButton from "../GenreSelectionButton/GenreSelectionButton";
import "./GenreSelection.css";

const GenreSelection = (props) => {
  const handleGenreSelection = (e) => {
    const targetID = parseInt(e.target.id);
    switch (props.genres.includes(targetID)) {
      case true:
        props.setGenres(props.genres.filter((genre) => genre !== targetID));
        break;
      case false:
        props.setGenres((prevState) => [...prevState, targetID]);
        break;
      default:
        break;
    }
  };

  return (
    <div className="GenreSelection">
      {allGenres
        .sort((a, b) => a - b)
        .map((genre) => {
          return (
            <GenreSelectionButton
              id={genre.id}
              key={genre.id}
              value={genre.name}
              onClick={handleGenreSelection}
              name={genre.name}
              active={false}
            />
          );
        })}
    </div>
  );
};

export default GenreSelection;
