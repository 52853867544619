import React, {useState, useEffect} from "react";
import Show from "../Show/Show";
import "./TvList.css";

const TvList = (props) => {
  const [shows, setShows] = useState([]);
    
  useEffect(()=>{
    setShows(props.shows)
  }, [props.shows])

  return (
    <div className="TvList">
        {shows.length !== 0 && shows.map((show) => {  
            let delta = Math.floor(Math.random() * 1000);
                           
          return (
            <Show
              setRegion={props.setRegion}
              key={`${show.id}${delta}`}
              name={show.name}
              img={show.poster_path}
              overview={show.overview}
              id={show.id}
              genres={show.genre_ids}
              first_air_date={show.first_air_date}
              vote_average={show.vote_average}
              vote_count={show.vote_count}
              region={props.region}
            />
          );
        })}
    </div>
  );
};

export default TvList;
