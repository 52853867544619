const axios = require("axios");
const emojiFlags = require("emoji-flags");

export const getFlag = (countryCode) => {
  return emojiFlags.countryCode(countryCode);
};

export const endpoint = "http://localhost:8080";

export const regions = [
  {
    iso_3166_1: "AE",
    english_name: "United Arab Emirates",
    native_name: "United Arab Emirates",
  },
  {
    iso_3166_1: "AR",
    english_name: "Argentina",
    native_name: "Argentina",
  },
  {
    iso_3166_1: "AT",
    english_name: "Austria",
    native_name: "Austria",
  },
  {
    iso_3166_1: "AU",
    english_name: "Australia",
    native_name: "Australia",
  },
  {
    iso_3166_1: "BE",
    english_name: "Belgium",
    native_name: "Belgium",
  },
  {
    iso_3166_1: "BG",
    english_name: "Bulgaria",
    native_name: "Bulgaria",
  },
  {
    iso_3166_1: "BR",
    english_name: "Brazil",
    native_name: "Brazil",
  },
  {
    iso_3166_1: "CA",
    english_name: "Canada",
    native_name: "Canada",
  },
  {
    iso_3166_1: "CH",
    english_name: "Switzerland",
    native_name: "Switzerland",
  },
  {
    iso_3166_1: "CZ",
    english_name: "Czech Republic",
    native_name: "Czech Republic",
  },
  {
    iso_3166_1: "DE",
    english_name: "Germany",
    native_name: "Germany",
  },
  {
    iso_3166_1: "DK",
    english_name: "Denmark",
    native_name: "Denmark",
  },
  {
    iso_3166_1: "EE",
    english_name: "Estonia",
    native_name: "Estonia",
  },
  {
    iso_3166_1: "ES",
    english_name: "Spain",
    native_name: "Spain",
  },
  {
    iso_3166_1: "FI",
    english_name: "Finland",
    native_name: "Finland",
  },
  {
    iso_3166_1: "FR",
    english_name: "France",
    native_name: "France",
  },
  {
    iso_3166_1: "GB",
    english_name: "United Kingdom",
    native_name: "United Kingdom",
  },
  {
    iso_3166_1: "HK",
    english_name: "Hong Kong",
    native_name: "Hong Kong SAR China",
  },
  {
    iso_3166_1: "HR",
    english_name: "Croatia",
    native_name: "Croatia",
  },
  {
    iso_3166_1: "HU",
    english_name: "Hungary",
    native_name: "Hungary",
  },
  {
    iso_3166_1: "ID",
    english_name: "Indonesia",
    native_name: "Indonesia",
  },
  {
    iso_3166_1: "IE",
    english_name: "Ireland",
    native_name: "Ireland",
  },
  {
    iso_3166_1: "IN",
    english_name: "India",
    native_name: "India",
  },
  {
    iso_3166_1: "IT",
    english_name: "Italy",
    native_name: "Italy",
  },
  {
    iso_3166_1: "JP",
    english_name: "Japan",
    native_name: "Japan",
  },
  {
    iso_3166_1: "KR",
    english_name: "South Korea",
    native_name: "South Korea",
  },
  {
    iso_3166_1: "LT",
    english_name: "Lithuania",
    native_name: "Lithuania",
  },
  {
    iso_3166_1: "MX",
    english_name: "Mexico",
    native_name: "Mexico",
  },
  {
    iso_3166_1: "NL",
    english_name: "Netherlands",
    native_name: "Netherlands",
  },
  {
    iso_3166_1: "NO",
    english_name: "Norway",
    native_name: "Norway",
  },
  {
    iso_3166_1: "NZ",
    english_name: "New Zealand",
    native_name: "New Zealand",
  },
  {
    iso_3166_1: "PH",
    english_name: "Philippines",
    native_name: "Philippines",
  },
  {
    iso_3166_1: "PL",
    english_name: "Poland",
    native_name: "Poland",
  },
  {
    iso_3166_1: "PT",
    english_name: "Portugal",
    native_name: "Portugal",
  },
  {
    iso_3166_1: "RU",
    english_name: "Russia",
    native_name: "Russia",
  },
  {
    iso_3166_1: "SE",
    english_name: "Sweden",
    native_name: "Sweden",
  },
  {
    iso_3166_1: "SK",
    english_name: "Slovakia",
    native_name: "Slovakia",
  },
  {
    iso_3166_1: "TR",
    english_name: "Turkey",
    native_name: "Turkey",
  },
  {
    iso_3166_1: "US",
    english_name: "United States of America",
    native_name: "United States",
  },
  {
    iso_3166_1: "ZA",
    english_name: "South Africa",
    native_name: "South Africa",
  },
];

export const allGenres = [
  { id: 28, name: "Action" },
  { id: 12, name: "Adventure" },
  { id: 16, name: "Animation" },
  { id: 35, name: "Comedy" },
  { id: 80, name: "Crime" },
  { id: 99, name: "Documentary" },
  { id: 18, name: "Drama" },
  { id: 10751, name: "Family" },
  { id: 14, name: "Fantasy" },
  { id: 36, name: "History" },
  { id: 27, name: "Horror" },
  { id: 10402, name: "Music" },
  { id: 9648, name: "Mystery" },
  { id: 10749, name: "Romance" },
  { id: 878, name: "Science Fiction" },
  { id: 10770, name: "TV Movie" },
  { id: 53, name: "Thriller" },
  { id: 10752, name: "War" },
  { id: 37, name: "Western" },
  { id: 10759, name: "Action & Adventure" },
  { id: 10762, name: "Kids" },
  { id: 10763, name: "News" },
  { id: 10764, name: "Reality" },
  { id: 10765, name: "Sci-Fi & Fantasy" },
  { id: 10766, name: "Soap" },
  { id: 10767, name: "Talk" },
  { id: 10768, name: "War & Politics" },
];

export const languages = [
  "ar-AE",
  "bg-BG",
  "ca-ES",
  "cs-CZ",
  "de-DE",
  "da-DK",
  "en-GB",
  "fi-FI",
  "fr-FR",
  "hr-HR",
  "hu-HU",
  "id-ID",
  "it-IT",
  "ja-JP",
  "ko-KR",
  "lt-LT",
  "nl-NL",
  "no-NO",
  "pl-PL",
  "pt-PT",
  "ru-RU",
  "sv-SE",
  "sk-SK",
  "tr-TR",
];

export const searchByTitle = async (term, media, language) => {
  let results = await axios
    .request(`${endpoint}/${media}/search/${term}?lan=${language}`)
    .then((response) => {
      return response.data.results;
    })
    .catch((err) => console.log(err));

  return results;
};

export const fetchTrendingMoviesWeek = async (media, period) => {
  let results = await axios
    .request(`${endpoint}/trending/${media}/${period}`)
    .then((response) => {
      return response.data.results;
    })
    .catch((err) => {
      console.log(err);
    });

  return results;
};

export const fetchDetails = async (id, media) => {
  return await axios
    .request(`${endpoint}/${media}/details/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchCredits = async (id, media) => {
  return await axios
    .request(`${endpoint}/${media}/credits/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchProviders = async (id, region, media) => {
  return await axios
    .request(`${endpoint}/${media}/providers/${id}?region=${region}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const getAllRegionsProviders = async (id, media) => {
  return await axios
    .request(`${endpoint}/${media}/flatrates/${id}`)
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchDiscover = async (
  region,
  media,
  provider,
  language,
  genre = ""
) => {
  return await axios
    .request(
      `${endpoint}/${media}/discover/${provider}?region=${region}&lan=${language}&genre=${genre}`
    )
    .then((response) => {
      return response.data;
    })
    .catch((err) => {
      console.log(err);
    });
};

export const fetchRegion = async () => {
  const region = await axios
    .get(`https://geolocation-db.com/json/`)
    .then((response) => {
      return response.data;
    });
  return region.country_code;
};

export const determineLanguage = (region) => {
  let lang;
  for (let i = 0; i < languages.length; i++) {
    if (region === languages[i].substring(languages[i].length - 2)) {
      lang = languages[i];
      return lang;
    }
  }
};

export const splitInGroups = (obj, media, movieNo, provider = null) => {
  let target = obj[media].results;
  if (provider !== null) {
    target = obj[media].results[provider];
  }

  let i = target.length / movieNo;
  let indexToPush = 0;
  let results = [];
  for (let y = 0; y < i; y++) {
    results.push(target.slice(indexToPush, indexToPush + movieNo));
    indexToPush += movieNo;
  }
  return results;
};
