import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { determineLanguage } from "../../utils/utils";

export const languageSlice = createSlice({
  name: "language",
  initialState: {
    language: "",
    isLoading: false,
    hasError: false,
    errorMessage: "",
  },
  reducers: {
    changeLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDefaultLanguage.pending, (state, action) => {
        state.isLoading = true;
        state.hasError = false;
      })
      .addCase(fetchDefaultLanguage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.language = action.payload;
      })
      .addCase(fetchDefaultLanguage.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.errorMessage = action.payload;
      });
  },
});

export const fetchDefaultLanguage = createAsyncThunk(
  "language/fetchDefaultLanguage",
  async (region) => {
    const response = await determineLanguage(region);
    return response;
  }
);

//Export actions and reducer
export const { changeLanguage } = languageSlice.actions;
export default languageSlice.reducer;

//Export selector functions
export const selectLanguage = (state) => state.language.language;
export const selectIsLoadingLanguage = (state) => state.language.isLoading;
