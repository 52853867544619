import React, { useEffect, useState } from "react";
import { CSSTransition } from "react-transition-group";
import RegionSelector from "../RegionSelector/RegionSelector";
import StreamingPlatform from "../StreamingPlatform/StreamingPlatform";
import { fetchDetails, fetchCredits } from "../../../app/utils/utils";
import { useSelector } from "react-redux";
import { selectRegion } from "../../../app/store/slices/regionSlice";

const MovieModal = (props) => {
  const { img, title, overview, id, media } = props;

  const imgUrl = `https://image.tmdb.org/t/p/w342${img}`;

  const releaseDate = props.release_date;
  const initialRegion = useSelector(selectRegion);

  const [region, setRegion] = useState(initialRegion);
  const [director, setDirector] = useState("");

  const [tagline, setTagline] = useState("");
  const [movieRuntime, setMovieRuntime] = useState(0);
  const [genres, setGenres] = useState([]);
  const [showSynopsis, setShowSynopsis] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showSynButton, setShowSynButton] = useState(true);
  const [showInfoButton, setShowInfoButton] = useState(true);

  //EFFECT HOOKS
  useEffect(() => {
    fetchMovieCredits(id, media);
    fetchMovieDetails(id, media);
  }, [id, media]);

  //ASYNC DATA RETRIEVE FUNCTION
  const fetchMovieCredits = async (id, media) => {
    let credits = await fetchCredits(id, media).then((credits) => {
      return credits;
    });
    credits.crew.forEach((crewMember) => {
      if (crewMember.job === "Director") {
        setDirector(crewMember.name);
      }
    });
  };

  const fetchMovieDetails = async (id, media) => {
    let details = await fetchDetails(id, media).then((details) => {
      return details;
    });

    setGenres(details.genres);
    setTagline(details.tagline);
    setMovieRuntime(details.runtime);
  };

  //HELPER FUNCTIONS
  const handleCloseClick = () => {
    props.setShowModal(false);
  };

  const handleSynClick = (e) => {
    setShowSynopsis((s) => !s);
    setShowSynButton((s) => !s);
  };

  const handleInfoClick = (e) => {
    setShowInfo((s) => !s);
    setShowInfoButton((s) => !s);
  };

  return (
    <div className="test">
      <div className="Modal">
        <div className="Modal-close">
          <button type="button" onClick={handleCloseClick}>
            X
          </button>
        </div>
        <div className="Modal-img">
          <img alt="poster" src={imgUrl} />
        </div>
        <div className="Modal-contents">
          <div className="Modal-info">
            <h1>{title}</h1>
            {/* SHOW SYNOPSIS */}
            <CSSTransition
              in={showSynopsis}
              timeout={300}
              classNames="ModalSynopsis"
              unmountOnExit
            >
              <>
                <p>{overview}</p>
                <p>"{tagline}"</p>

                {showSynopsis && (
                  <button className="Modal-Button" onClick={handleSynClick}>
                    Hide Synopsis
                  </button>
                )}
              </>
            </CSSTransition>

            <>
              {showSynButton && (
                <button className="Modal-Button" onClick={handleSynClick}>
                  Show Synopsis
                </button>
              )}
            </>
            {/* SHOW INFO */}
            <CSSTransition
              in={showInfo}
              timeout={300}
              classNames="ModalInfo"
              unmountOnExit
            >
              <>
                <ul>
                  <li>
                    <b>Director</b>: {director}
                  </li>
                  <li>
                    <b>Movie Duration</b>: {movieRuntime} minutes
                  </li>
                  <li>
                    <b>Release date</b>: {releaseDate}
                  </li>
                  <li>
                    <b>Genre</b>:{" "}
                    {genres.map((genre) => {
                      return `${genre.name}, `;
                    })}
                  </li>
                </ul>
                <button className="Modal-Button" onClick={handleInfoClick}>
                  Hide Info
                </button>
              </>
            </CSSTransition>
            {showInfoButton && (
              <button className="Modal-Button" onClick={handleInfoClick}>
                Show Info
              </button>
            )}
          </div>
          <div className="Modal-streaming-regions">
            <div className="Modal-platforms">
              <StreamingPlatform region={region} id={id} media="movie" />
            </div>
            <div className="Modal-regions">
              <RegionSelector region={region} setRegion={setRegion} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MovieModal;
