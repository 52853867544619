import React from "react";
import "./SliderButton.css";

const SliderButton = (props) => {
  const handleClick = (e) => {
    switch (e.target.id) {
      default:
        return;
      case "backward":
        if (props.counter - 1 < 0) {
          props.setCounter(props.len);
          return;
        }
        props.setCounter(props.counter - 1);
        break;
      case "forward":
        if (props.counter + 1 > props.len) {
          props.setCounter(0);
          return;
        }
        props.setCounter(props.counter + 1);
    }
  };

  return (
    <div className="handle left-handle" id={props.arrow} onClick={handleClick}>
      {props.arrow === "forward" ? ">" : "<"}
    </div>
  );
};

export default SliderButton;
