import React, { useState } from "react";
import Discover from "../Discover/Discover";
import Miscellaneous from "../Miscellaneous/Miscellaneous";
import GenreSelection from "../GenreSelection/GenreSelection";
import {
  selectLanguage,
  selectIsLoadingLanguage,
} from "../../../app/store/slices/languageSlice";
import {
  selectRegion,
  selectIsLoading,
} from "../../../app/store/slices/regionSlice";
import { useSelector } from "react-redux";

const Lists = (props) => {

  //Selectors
  const region = useSelector(selectRegion);
  const loadingRegion = useSelector(selectIsLoading);
  const language = useSelector(selectLanguage);
  const loadingLanguage = useSelector(selectIsLoadingLanguage);

  const providers = ["Netflix", "Amazon", "Disney"];
  const movieNo = 5;
  const [genres, setGenres] = useState([]);

  //conditional render
  if (loadingRegion) {
    return <p>Loading</p>;
  }

  if (loadingLanguage) {
    return <p>Fetching language..</p>;
  }

  return (
    <div className="Lists">
      <div className="Lists-Genres">
        <GenreSelection setGenres={setGenres} genres={genres} />
      </div>
      <div className="Lists-Lists">
        {/* General Movies and Tv Shows in order of votes  */}
        <Miscellaneous
          providers={providers}
          media="movie"
          movieNo={movieNo}
          genres={genres}
          region={region}
          language={language}
        />

        <Miscellaneous
          providers={providers}
          media="tv"
          movieNo={movieNo}
          genres={genres}
          region={region}
          language={language} />

        {/*Discover Movies based on providers*/}
        {providers.map((provider) => {
          return (
            <Discover
              key={`${provider}-Movies`}
              media="movie"
              provider={provider}
              movieNo={movieNo}
              genres={genres}
              region={region}
              language={language} />
          );
        })}
        {/*Discover Movies based on providers*/}
        {providers.map((provider) => {
          return (
            <Discover
              key={`${provider}-TvShows`}
              media="tv"
              provider={provider}
              movieNo={movieNo}
              genres={genres}
              region={region}
              language={language} />
          );
        })}
      </div>
    </div>
  );
};

export default Lists;
