import React, { useState, useEffect } from "react";
import "./Movie.css";
import MovieModal from "../MovieModal/MovieModal";
import noPicture from "../../static/imgs/no-picture.jpg";
import { CSSTransition } from "react-transition-group";
import loadingGif from "../../static/imgs/loading.gif";

const Movie = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [img, setImg] = useState(loadingGif);

  useEffect(() => {
    if (props.img !== null) {
      setImg(`https://image.tmdb.org/t/p/w185${props.img}`);
    }
    if (props.img === null) {
      setImg(noPicture);
    }
  }, [props.img]);

  return (
    <div className="Movie">
      <img
        src={img}
        alt="movie-poster-unavailable"
        onClick={() => {
          setShowModal(!showModal);
        }}
      />
      <p>{props.title}</p>
      <CSSTransition
        in={showModal}
        timeout={300}
        classNames="Movie-modal-transition"
        unmountOnExit
      >
        <MovieModal
          title={props.title}
          img={props.img}
          overview={props.overview}
          id={props.id}
          genres={props.genres}
          release_date={props.release_date}
          vote_average={props.vote_average}
          vote_count={props.vote_count}
          setShowModal={setShowModal}
          region={props.region}
          setRegion={props.setRegion}
          media="movie"
        />
      </CSSTransition>
    </div>
  );
};

export default Movie;
