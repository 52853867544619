import React, { useState, useEffect } from "react";
import MovieList from "../MovieList/MovieList";
import TvList from "../TvList/TvList";
import SliderButton from "../SliderButton/SliderButton";
import { splitInGroups } from "../../../app/utils/utils";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchDiscoverAsync,
  selectFetchingDiscover,
  selectAllDiscover,
} from "../../../app/store/slices/discoverSlice";

import "./Discover.css";

export const Discover = (props) => {
  //Dispatch and selectors
  const dispatch = useDispatch();
  const isLoading = useSelector(selectFetchingDiscover);
  const discover = useSelector(selectAllDiscover);

  const { region, media, provider, language, genres, movieNo } = props;

  //STATE HOOKS AND VARIABLES
  const [results, setResults] = useState([]);
  const [len, setLen] = useState(results / movieNo - 1);
  const [counter, setCounter] = useState(0);

  // EFFECT HOOKS

  useEffect(() => {
    dispatch(
      fetchDiscoverAsync({
        region: region,
        media: media,
        provider: provider,
        language: language,
        genres: genres,
      })
    );
  }, [region, media, provider, language, movieNo, genres, dispatch]);

  useEffect(() => {
    const results = splitInGroups(discover, media, movieNo, provider);
    setResults(results);
    setLen(results.length - 1);
  }, [discover, provider, media, movieNo]);

  if (isLoading) {
    return (
      <>
        <img
          src="../../static/img/loading.gif"
          alt="Loading"
          aria-label="loading"
        />
      </>
    );
  }

  /* <div>
            <p>{`No ${
              props.media === "movie" ? "Movie" : "Tv Show"
            } found matching your search criteria, some genres could only apply to either Movies or Tv Shows`}</p>
          </div> */

  return (
    <div className="Discover">
      <div className="Discover-header">
        <h2>
          Discover {props.media === "movie" ? "Movies" : "Series"} on{" "}
          {props.provider} :
        </h2>
      </div>
      <div className="Discover-content">
        <SliderButton
          setCounter={setCounter}
          counter={counter}
          arrow="backward"
          len={len}
        />

        {results.length !== 0 ? (
          <div className="Discover-list">
            {props.media === "movie" ? (
              <MovieList
                movies={results[counter]}
                region={props.region}
                setRegion={props.setRegion}
              />
            ) : (
              <TvList
                shows={results[counter]}
                region={props.region}
                setRegion={props.setRegion}
              />
            )}
          </div>
        ) : (
          ""
        )}

        <SliderButton
          setCounter={setCounter}
          counter={counter}
          arrow="forward"
          len={len}
        />
      </div>
    </div>
  );
};

export default Discover;
