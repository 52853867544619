import React from "react";
import Nav from "../Nav/Nav";
import { Link } from "react-router-dom";
import "./Header.css";

const Header = (props) => {
  return (
    <div className="Header">
      <div className="Header-text">
        <h1>
          <Link to="/">StreamFinder</Link>
        </h1>
      </div>
      <div className="Header-Nav">
        <Nav region={props.region} language={props.language} />
      </div>
    </div>
  );
};

export default Header;
