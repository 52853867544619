import React from "react";
import "./Nav.css";
import { regions, languages } from "../../../app/utils/utils";
import { useDispatch } from "react-redux";
import { changeLanguage } from "../../../app/store/slices/languageSlice";
import { changeRegion } from "../../../app/store/slices/regionSlice";

const Nav = (props) => {
  const dispatch = useDispatch();

  const handleOnChange = (e) => {
    console.log(e.target.id);
    switch (e.target.id) {
      case "region":
        dispatch(changeRegion(e.target.value));
        break;
      case "language":
        dispatch(changeLanguage(e.target.value));
        break;
      default:
        return;
    }
  };

  return (
    <ul className="Nav-list">
      <li>
        <a href="/">Home</a>
      </li>
      <li>
        <a href="/search">Search</a>
      </li>
      {/* <li>
        <a href="/suggestions">Suggestions and Feedback</a>
      </li> */}
      <li>
        <label>Select region: </label>
        <select
          value={props.region}
          name="Regions"
          id="region"
          className="RegionSelector-dropdown"
          onChange={handleOnChange}
        >
          {regions.map((region) => {
            return (
              <option key={region.iso_3166_1} value={region.iso_3166_1}>
                {region.iso_3166_1}
              </option>
            );
          })}
        </select>
      </li>
      <li>
        <label>Select language: </label>
        <select
          value={props.language}
          name="Languages"
          id="language"
          className="LanguageSelector-dropdown"
          onChange={handleOnChange}
        >
          {languages.map((language) => {
            return (
              <option key={language} value={language}>
                {language.substring(3).toUpperCase()}
              </option>
            );
          })}
        </select>
      </li>
    </ul>
  );
};

export default Nav;
