import React, { useState, useEffect } from "react";
import { splitInGroups } from "../../../app/utils/utils";
import SliderButton from "../SliderButton/SliderButton";
import MovieList from "../MovieList/MovieList";
import TvList from "../TvList/TvList";
import "./Miscellaneous.css";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAllDiscover,
  setMiscellaneousResults,
  selectAllMiscellanous,
} from "../../../app/store/slices/discoverSlice";
const Miscellaneous = (props) => {
  //Selectors & Vars
  const { region, media, providers, language, genres, movieNo } = props;
  const dispatch = useDispatch();
  const discover = useSelector(selectAllDiscover);
  const miscellaneous = useSelector(selectAllMiscellanous);

  //State hooks
  const resultMedia = discover[media].results;
  const [results, setResults] = useState([]);
  const [len, setLen] = useState(miscellaneous / movieNo - 1);
  const [counter, setCounter] = useState(0);

  // EFFECT HOOKS
  //At render and region, media and provider change
  useEffect(() => {
    let results = [];
    for (const key in resultMedia) {
      results = results.concat(resultMedia[key]).sort((a, b) => {
        if (a.vote_average === b.vote_average) {
          return b.vote_count - a.vote_count;
        }
        return b.vote_average - a.vote_average;
      });
    }
    const payload = { results: results, media: media };
    dispatch(setMiscellaneousResults(payload));
  }, [media, providers, language, region, genres, dispatch, resultMedia]);

  //when Miscellaneous results change
  useEffect(() => {
    const results = splitInGroups(miscellaneous, media, movieNo);
    setResults(results);
    setLen(results.length - 1);
  }, [resultMedia, movieNo, miscellaneous, media]);

  return (
    <div className="Miscellaneous">
      <div className="Miscellaneous-header">
        <h2>
          Popular {props.media === "movie" ? "Movies" : "Tv Show"} in Your
          Country :
        </h2>
      </div>
      <div className="Miscellaneous-content">
        <SliderButton
          setCounter={setCounter}
          counter={counter}
          arrow="backward"
          len={len}
        />
        {results.length !== 0 ? (
          <div className="Discover-list">
            {props.media === "movie" ? (
              <MovieList movies={results[counter]} region={props.region} />
            ) : (
              <TvList shows={results[counter]} region={props.region} />
            )}
          </div>
        ) : (
          <div>
            <p>{`No ${props.media === "movie" ? "Movie" : "Tv Show"
              } found matching your search criteria, some genres could only apply to either Movies or Tv Shows`}</p>
          </div>
        )}
        <SliderButton
          setCounter={setCounter}
          counter={counter}
          arrow="forward"
          len={len}
        />
      </div>
    </div>
  );
};

export default Miscellaneous;
