import React, { useState } from "react";

const Suggestion = (props) => {
  const { body, title } = props;

  const [counter, setCounter] = useState(0);

  return (
    <li>
      <h3>{title}</h3>
      <p>{body}</p>
      <p>Votes: {counter}</p>
      <button
        onClick={() => {
          setCounter(counter + 1);
        }}
      >
        Upvote
      </button>
    </li>
  );
};

export default Suggestion;
