import React from "react";
import { suggestions } from "./test-sugg";
import Suggestion from "../Suggestion/Suggestion";
import "./Suggest.css";

const Suggest = () => {
  return (
    <div className="Suggest">
      <ul className="Suggest-list">
        {suggestions.map((suggestion) => {
          return (
            <Suggestion
              key={suggestion.id}
              body={suggestion.body}
              title={suggestion.title}
            />
          );
        })}
      </ul>
    </div>
  );
};

export default Suggest;
