import "./App.css";
import "../../../app/utils/main.css";
import Header from "../Header/Header";
import Lists from "../Lists/Lists";
import AdvancedSearch from "../AdvancedSearch/AdvancedSearch";
import Suggest from "../Suggest/Suggest";
import Footer from "../Footer/Footer";

import { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { selectLanguage } from "../../../app/store/slices/languageSlice";
import {
  selectRegion,
  fetchUserRegion,
} from "../../../app/store/slices/regionSlice";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();

  //Selectors
  const region = useSelector(selectRegion);
  const language = useSelector(selectLanguage);

  useEffect(() => {
    dispatch(fetchUserRegion());
  }, [dispatch]);

  return (
    <div className="App">
      <Router>
        <Header region={region} language={language} />
        <Routes>
          <Route
            path="*"
            element={<Lists region={region} language={language} />}
          />
          <Route path="/suggestions" element={<Suggest />} />

          <Route
            path={`/search`}
            element={<AdvancedSearch region={region} />}
          />
        </Routes>
        <Footer />
      </Router>
    </div>
  );
}

export default App;
