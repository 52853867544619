export const suggestions = [
  {
    id: "1",
    title: "Title",
    body: "I think you should quit",
  },
  {
    id: "2",
    title: "Title2",
    body: "No seriously, quit",
  },
];
