import { fetchDiscover } from "../../utils/utils";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

export const discoverSlice = createSlice({
  name: "discover",
  initialState: {
    discover: {
      tv: {
        results: {
          Amazon: {},
          Netflix: {},
          Disney: {},
        },
      },
      movie: {
        results: {
          Amazon: {},
          Netflix: {},
          Disney: {},
        },
      },
      isFetching: false,
      hasError: false,
    },
    miscellaneous: {
      tv: {
        results: [],
      },
      movie: {
        results: [],
      },
      isFetching: false,
      hasError: false,
    },
  },
  reducers: {
    setMiscellaneousResults: (state, action) => {
      const { media, results } = action.payload;
      let cleanedFromDuplicates = [];
      let ids = [];
      results.forEach((entry) => {
        if (!ids.includes(entry.id)) {
          cleanedFromDuplicates.push(entry);
          ids.push(entry.id);
        }
      });
      state.miscellaneous[media].results = cleanedFromDuplicates;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchDiscoverAsync.pending, (state, action) => {
        state.discover.isFetching = true;
        state.discover.hasError = false;
      })
      .addCase(fetchDiscoverAsync.fulfilled, (state, action) => {
        const { media, provider, results } = action.payload;
        state.discover.isFetching = false;
        state.discover.hasError = false;
        state.discover[media].results[provider] = results;
      })
      .addCase(fetchDiscoverAsync.rejected, (state) => {
        state.discover.hasError = true;
        state.discover.isFetching = false;
      });
  },
});

export const fetchDiscoverAsync = createAsyncThunk(
  "discover/fetchDiscover",
  async (data) => {
    const { region, media, provider, language, genres } = data;
    const response = await fetchDiscover(
      region,
      media,
      provider,
      language,
      genres
    );
    const payload = {
      provider: provider,
      media: media,
      results: response.results,
    };
    return payload;
  }
);

//Selector functions
export const selectFetchingDiscover = (state) =>
  state.discover.discover.isFetching;
export const selectHasErrorDiscover = (state) =>
  state.discover.discover.hasError;
export const selectAllDiscover = (state) => state.discover.discover;

export const selectAllMiscellanous = (state) => state.discover.miscellaneous;
export const selectFetchingMiscellanous = (state) =>
  state.discover.miscellaneous.isFetching;
export const selectHasErrorMiscellanous = (state) =>
  state.discover.miscellaneous.hasError;

//export actions and reducers
export const { setMiscellaneousResults } = discoverSlice.actions;
export default discoverSlice.reducer;
