import React, { useState, useEffect } from "react";
import Movie from "../Movie/Movie";

import "./MovieList.css";

const MovieList = (props) => {
  const [movies, setMovies] = useState(props.movies);
  let delta = Math.floor(Math.random() * 10);

  useEffect(() => {
    setMovies(props.movies);
  }, [props.movies]);

  return (
    <div className="MovieList">
      {movies.length !== 0 &&
        movies.map((movie) => {
          return (
            <Movie
              setRegion={props.setRegion}
              key={`${movie.id}${delta}`}
              title={movie.title}
              img={movie.poster_path}
              backdrop={movie.backdrop_path}
              overview={movie.overview}
              id={movie.id}
              genres={movie.genre_ids}
              release_date={movie.release_date}
              vote_average={movie.vote_average}
              vote_count={movie.vote_count}
              region={props.region}
            />
          );
        })}
    </div>
  );
};

export default MovieList;
