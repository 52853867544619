import React from "react";
import "./SearchBar.css";

const SearchBar = (props) => {
  const handleTitleChange = (e) => {
    props.setTerm(e.target.value);
  };

  return (
    <div className="SearchBar">
      <input
        type="text"
        placeholder="Search Movie Title"
        value={props.term}
        onChange={handleTitleChange}
      />
      {/*  {term.length > 0 ? (
        <Navigate to={`/search?q=${term}`} />
      ) : (
        <Navigate to={`/`} />
      )} */}
    </div>
  );
};

export default SearchBar;
