import React from "react";
import "./PlatformList.css";
import Grid from "@mui/material/Grid";

const PlatformList = (props) => {
  //Selectors

  const { action, providers, streamingRegions } = props;

  if (providers === undefined) {
    return (
      <>
        <p>
          Not available for{" "}
          {action === "Buy"
            ? "Purchase"
            : action === "Stream"
            ? "Streaming"
            : "Renting"}{" "}
          yet.
        </p>
        {action === "Stream" ? (
          <>
            <p>...BUT you could check one of these regions:</p>
            {streamingRegions.map((region) => {
              if (
                streamingRegions.indexOf(region) ===
                streamingRegions.length - 1
              ) {
                return <span>{region}.</span>;
              }
              return <span>{region}, </span>;
            })}
          </>
        ) : (
          ""
        )}
      </>
    );
  }

  return (
    <div className="PlatformList">
      <h2>{action} On:</h2>
      <Grid container spacing={1}>
        {providers.map((provider) => {
          return (
            <Grid item key={`${provider.provider_name}-${action}`}>
              <img
                alt={provider.provider_name}
                src={`https://image.tmdb.org/t/p/w45${provider.logo_path}`}
              ></img>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
};

export default PlatformList;
