import React, { useState, useEffect } from "react";
import "./Show.css";
import ShowModal from "../ShowModal/ShowModal";
import noPicture from "../../static/imgs/no-picture.jpg";
import loadingGif from "../../static/imgs/loading.gif";
import { CSSTransition } from "react-transition-group";

const Show = (props) => {
  const [showModal, setShowModal] = useState(false);
  const [img, setImg] = useState(loadingGif);

  useEffect(() => {
    if (props.img !== null) {
      setImg(`https://image.tmdb.org/t/p/w185${props.img}`);
    }
    if (props.img === null) {
      setImg(noPicture);
    }
  }, [props.img]);

  return (
    <div className="Movie">
      <img
        src={img}
        alt="Show-poster-unavailable"
        onClick={() => {
          setShowModal(!showModal);
        }}
      />
      <p>{props.name}</p>
      <CSSTransition
        in={showModal}
        timeout={300}
        classNames="my-node"
        unmountOnExit
      >
        <ShowModal
          name={props.name}
          img={props.img}
          overview={props.overview}
          id={props.id}
          genres={props.genres}
          first_air_date={props.first_air_date}
          vote_average={props.vote_average}
          vote_count={props.vote_count}
          setShowModal={setShowModal}
          region={props.region}
          setRegion={props.setRegion}
          media="tv"
        />
      </CSSTransition>
    </div>
  );
};

export default Show;
