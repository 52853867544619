import React, { useState, useEffect } from "react";
import { CSSTransition } from "react-transition-group";
import RegionSelector from "../RegionSelector/RegionSelector";
import StreamingPlatform from "../StreamingPlatform/StreamingPlatform";
import { fetchDetails, fetchCredits } from "../../../app/utils/utils";
import "../../../app/utils/main.css";
import { selectRegion } from "../../../app/store/slices/regionSlice";
import { useSelector } from "react-redux";

const ShowModal = (props) => {
  const initialRegion = useSelector(selectRegion);

  const img = `https://image.tmdb.org/t/p/w342${props.img}`;
  const name = props.name;
  const overview = props.overview;
  const firstAirDate = props.first_air_date;

  const [region, setRegion] = useState(initialRegion);
  const [director, setDirector] = useState("");
  const [tagline, setTagline] = useState("");
  const [genres, setGenres] = useState([]);
  const [lastAirDate, setLastAirDate] = useState("");
  const [seasonsNo, setSeasonsNo] = useState(0);
  const [status, setStatus] = useState("");
  const [inProduction, setInProduction] = useState(
    "not currently in production"
  );
  const [episodesNo, setEpisodesNo] = useState(0);
  const [showSynopsis, setShowSynopsis] = useState(false);
  const [showInfo, setShowInfo] = useState(false);
  const [showSynButton, setShowSynButton] = useState(true);
  const [showInfoButton, setShowInfoButton] = useState(true);

  //EFFECT HOOKS
  useEffect(() => {
    fetchShowDetails(props.id, props.media);
    fetchShowCredits(props.id, props.media);
  }, [props.id, props.media]);

  //ASYNC DATA RETRIEVE FUNCTION
  const fetchShowCredits = async (id, media) => {
    let credits = await fetchCredits(id, media).then((credits) => {
      return credits;
    });
    credits.crew.forEach((crewMember) => {
      if (crewMember.job === "Director") {
        setDirector(crewMember.name);
      }
    });
  };

  const fetchShowDetails = async (id, media) => {
    let details = await fetchDetails(id, media).then((details) => {
      return details;
    });
    setStatus(details.status);
    setGenres(details.genres);
    setTagline(details.tagline);
    setLastAirDate(details.last_air_date);
    setSeasonsNo(details.number_of_seasons);
    if (details.in_production === true) {
      setInProduction("currently in production");
    }
    setEpisodesNo(details.number_of_episodes);
  };

  //HELPER FUNCTIONS
  const handleCloseClick = (e) => {
    props.setShowModal(false);
  };

  const handleSynClick = (e) => {
    setShowSynopsis((s) => !s);
    setShowSynButton((s) => !s);
  };

  const handleInfoClick = (e) => {
    setShowInfo((s) => !s);
    setShowInfoButton((s) => !s);
  };

  return (
    <div className="Modal">
      <div className="Modal-close">
        <button type="button" onClick={handleCloseClick}>
          X
        </button>
      </div>
      <div className="Modal-img">
        <img alt="poster" src={img} />
      </div>
      <div className="Modal-contents">
        <div className="Modal-info">
          <h1>{name}</h1>
          {/* SHOW SYNOPSIS */}

          <CSSTransition
            in={showSynopsis}
            timeout={300}
            classNames="ModalSynopsis"
            unmountOnExit
          >
            <>
              <p>{overview}</p>
              <p>"{tagline}"</p>
              {showSynopsis && (
                <button className="Modal-Button" onClick={handleSynClick}>
                  Hide Synopsis
                </button>
              )}
            </>
          </CSSTransition>
          {showSynButton && (
            <button className="Modal-Button" onClick={handleSynClick}>
              Show Synopsis
            </button>
          )}

          {/* SHOW INFO */}

          <CSSTransition
            in={showInfo}
            timeout={300}
            classNames="ModalInfo"
            unmountOnExit
          >
            <>
              <ul>
                <li>
                  <b>Director</b>: {director}
                </li>
                <li>
                  <b>First / Last Episode Date</b>: {firstAirDate} /{" "}
                  {lastAirDate}
                </li>
                <li>
                  <b>Seasons / Episodes:</b> {seasonsNo} / {episodesNo}
                </li>
                <li>
                  <b>Status:</b> {status}
                </li>
                <li>
                  <b>Genre</b>:{" "}
                  {genres.map((genre) => {
                    return `${genre.name}, `;
                  })}
                </li>
              </ul>
              <p>This series is {inProduction}</p>
              <button className="Modal-Button" onClick={handleInfoClick}>
                Hide Info
              </button>
            </>
          </CSSTransition>
          {showInfoButton && (
            <button className="Modal-Button" onClick={handleInfoClick}>
              Show Info
            </button>
          )}
        </div>
        <div className="Modal-streaming-regions">
          <div className="Modal-platforms">
            <StreamingPlatform region={region} id={props.id} media="tv" />
          </div>
          <div className="Modal-regions">
            <RegionSelector region={region} setRegion={setRegion} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowModal;
