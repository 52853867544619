import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchProviders } from "../../utils/utils";

export const providerSlice = createSlice({
  name: "providers",
  initialState: {
    providers: {},
    loading: false,
    error: false,
    errorMessage: "",
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProvidersAsync.pending, (state, action) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(fetchProvidersAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.error = false;
        state.providers = action.payload;
      });
  },
});

export const fetchProvidersAsync = createAsyncThunk(
  "providers/fetchProviders",
  async (data) => {
    const { id, media, region } = data;
    const response = await fetchProviders(id, region, media);
    delete response.link;
    return response;
  }
);

export default providerSlice.reducer;
export const selectBuy = (state) => state.providers.providers.buy;
export const selectRent = (state) => state.providers.providers.rent;
export const selectFlatrate = (state) => state.providers.providers.flatrate;
export const selectLoadingProviders = (state) =>
  state.providers.providers.loading;
