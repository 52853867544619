import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import PlatformList from "../PlatformList/PlatformList";
import { getAllRegionsProviders } from "../../../app/utils/utils";
import "./StreamingPlatform.css";
import {
  selectBuy,
  selectRent,
  selectFlatrate,
  selectLoadingProviders,
  fetchProvidersAsync,
} from "../../../app/store/slices/providerSlice";

const StreamingPlatform = (props) => {
  //Selectors
  const buyProviders = useSelector(selectBuy);
  const rentProviders = useSelector(selectRent);
  const flatrateProviders = useSelector(selectFlatrate);
  const loading = useSelector(selectLoadingProviders);

  //Dispatch
  const dispatch = useDispatch();

  //Props
  const { region, id, media } = props;
  const [flatrateRegion, setFlatrateRegion] = useState([]);
  const [filter, setFilter] = useState("Stream");

  // EFFECT HOOK
  //When id, region, media change
  useEffect(() => {
    const data = {
      id: id,
      media: media,
      region: region,
    };
    dispatch(fetchProvidersAsync(data));
    fetchWhereToStream(id, media);
  }, [id, media, region, dispatch]);

  //HELPER FUNCTIONS
  //Filter function
  const handleClick = (e) => {
    setFilter(e.target.id);
  };

  //ASYNC TO FETCH REGIONS WHERE MOVIES ARE AVAILABLE
  const fetchWhereToStream = async (id, media) => {
    const flatrates = await getAllRegionsProviders(id, media);
    setFlatrateRegion(flatrates);
  };

  if (loading) {
    return (
      <>
        <img
          src="../../static/imgs/loading.gif"
          alt="loading"
          aria-label="Loading Gif"
        />
      </>
    );
  }

  return (
    <div className="StreamingPlatform">
      <div className="StreamingPlatform-Filters">
        <div className="StreamingPlatform-Filters-head">
          <p>Filter for</p>
        </div>
        <div className="StreamingPlatform-Filters-buttons">
          <button
            className="Modal-Button StreamingPlatform-btn"
            id="Stream"
            onClick={handleClick}
          >
            Stream
          </button>
          <button
            className="Modal-Button StreamingPlatform-btn"
            id="Buy"
            onClick={handleClick}
          >
            Buy
          </button>
          <button
            className="Modal-Button StreamingPlatform-btn"
            id="Rent"
            onClick={handleClick}
          >
            Rent
          </button>
        </div>
      </div>
      <div className="StreamingPlatform-list">
        {filter === "Stream" ? (
          <PlatformList
            providers={flatrateProviders}
            streamingRegions={flatrateRegion}
            action="Stream"
          />
        ) : filter === "Buy" ? (
          <PlatformList providers={buyProviders} action="Buy" />
        ) : (
          <PlatformList providers={rentProviders} action="Rent" />
        )}

        <p className="disclaimer">
          Powered by{" "}
          <a href="https://www.justwatch.com/" target="_blank" rel="noreferrer">
            JustWatch
          </a>
        </p>
      </div>
    </div>
  );
};

export default StreamingPlatform;
