import React, { useState, useEffect } from "react";
import { searchByTitle, allGenres } from "../../../app/utils/utils";
import "./AdvancedSearch.css";
import Movie from "../Movie/Movie";
import Show from "../Show/Show";
import SearchBar from "../SearchBar/SearchBar";
import Grid from "@mui/material/Grid";

const AdvancedSearch = (props) => {
  const [term, setTerm] = useState("");
  const [media, setMedia] = useState("movie");
  const [results, setResults] = useState([]);
  const [genreIDs, setGenreIDs] = useState([]);
  const [selectedGenres, setSelectedGenres] = useState([]);

  const mediaChange = (e) => {
    setMedia(e.target.value);
  };

  const handleGenreSelection = (e) => {
    const targetID = parseInt(e.target.id);
    if (genreIDs.includes(targetID)) {
      setSelectedGenres((prevState) => [...prevState, targetID]);
      setGenreIDs(genreIDs.filter((genre) => genre !== targetID));
    }

    if (selectedGenres.includes(targetID)) {
      setGenreIDs((prevState) => [...prevState, targetID]);
      setSelectedGenres(selectedGenres.filter((genre) => genre !== targetID));
    }
  };

  const fetchByTitle = async (term, media, language) => {
    if (term === "") {
      return;
    }
    const results = await searchByTitle(term, media, language).then(
      (results) => {
        return results;
      }
    );
    return results;
  };

  //Effect hook to each term change
  useEffect(() => {
    fetchByTitle(term, media, props.language).then((response) => {
      setResults(response);
    });
  }, [term, media, props.language]);

  //Run when results change
  useEffect(() => {
    if (results !== undefined) {
      let ids = [];
      results.forEach((result) => {
        result.genre_ids.forEach((id) => {
          if (!ids.includes(id)) {
            ids.push(id);
          }
        });
      });
      setGenreIDs(ids);
      setSelectedGenres([]);
    }
    if (results === undefined) {
      setSelectedGenres([]);
      setGenreIDs([]);
    }
  }, [results]);

  return (
    <div className="AdvancedSearch">
      <div className="AdvancedSearch-Inputs">
        <ul>
          <li>
            <SearchBar region={props.region} setTerm={setTerm} term={term} />
          </li>
          <li id="AdvancedSearch-Media">
            <label className="AdvancedSearch-Label">Media: </label>
            <ul id="AdvancedSearch-media-type">
              <li>
                <label className="AdvancedSearch-Label">
                  <input
                    type="radio"
                    className="AdvancedSearch-radio"
                    value="Movie"
                    name="media"
                    defaultChecked
                    onChange={mediaChange}
                  />
                  <span>Movie</span>
                </label>
              </li>
              <li>
                <label className="AdvancedSearch-Label">
                  <input
                    type="radio"
                    className="AdvancedSearch-radio"
                    value="Tv"
                    name="media"
                    onChange={mediaChange}
                  />
                  Tv Show
                </label>
              </li>
            </ul>
          </li>
          <li>
            <label className="AdvancedSearch-Label">Genre: </label>
            {genreIDs
              .sort((a, b) => a - b)
              .map((genre) => {
                let text = "";
                for (let i = 0; i < allGenres.length; i++) {
                  if (genre === allGenres[i].id) {
                    text = allGenres[i].name;
                  }
                }
                return (
                  <button
                    id={genre}
                    key={genre}
                    className="AdvancedSearch-button"
                    value={genre}
                    name={genre}
                    onClick={handleGenreSelection}
                  >
                    {text}{" "}
                  </button>
                );
              })}
          </li>
          <li>
            <label className="AdvancedSearch-Label">Selected Genres: </label>
            {selectedGenres
              .sort((a, b) => a - b)
              .map((genre) => {
                let text = "";
                for (let i = 0; i < allGenres.length; i++) {
                  if (genre === allGenres[i].id) {
                    text = allGenres[i].name;
                  }
                }
                return (
                  <button
                    id={genre}
                    key={genre}
                    className="AdvancedSearch-button"
                    value={genre}
                    name={genre}
                    onClick={handleGenreSelection}
                  >
                    {text}
                  </button>
                );
              })}
          </li>
        </ul>
      </div>
      {/* OMG THIS IS SUCH A MESS LET ME COMMENT INLINE IMMEDIATELY */}
      <div className="AdvancedSearch-Results">
        <Grid container spacing={2}>
          {
            /// WE ONLY WANT TO FILTER IF GENRES ARE SELECTED
            selectedGenres.length !== 0
              ? results
                  .filter((movie) => {
                    //INITIALIZE BOOL VARIABLE AS FALSE, RUN CHECK ON EACH GENRE OF EACH MOVIE AND FILTER
                    let bool = false;
                    for (let i = 0; i < selectedGenres.length; i++) {
                      if (movie.genre_ids.includes(selectedGenres[i])) {
                        bool = true;
                      }
                    }
                    return bool;
                  })
                  .map((result) => {
                    return (
                      <Grid item key={result.id}>
                        {/* CONDITION CHECKING FOR MEDIA TYPE */}
                        {media === "movie" ? (
                          <Movie
                            setRegion={props.setRegion}
                            key={result.id}
                            title={result.title}
                            img={result.poster_path}
                            overview={result.overview}
                            id={result.id}
                            genres={result.genre_ids}
                            release_date={result.release_date}
                            vote_average={result.vote_average}
                            vote_count={result.vote_count}
                            region={props.region}
                          />
                        ) : (
                          <Show
                            setRegion={props.setRegion}
                            key={result.id}
                            name={result.name}
                            img={result.poster_path}
                            overview={result.overview}
                            id={result.id}
                            genres={result.genre_ids}
                            first_air_date={result.first_air_date}
                            vote_average={result.vote_average}
                            vote_count={result.vote_count}
                            region={props.region}
                          />
                        )}
                      </Grid>
                    );
                  })
              : //SO BASICALLY IF SELECTED GENRES IS EMPTY, WE JUST RENDER RESULTS
                results !== undefined &&
                results.map((result) => {
                  return (
                    <Grid item key={result.id}>
                      {media === "movie" ? (
                        <Movie
                          setRegion={props.setRegion}
                          key={result.id}
                          title={result.title}
                          img={result.poster_path}
                          overview={result.overview}
                          id={result.id}
                          genres={result.genre_ids}
                          release_date={result.release_date}
                          vote_average={result.vote_average}
                          vote_count={result.vote_count}
                          region={props.region}
                        />
                      ) : (
                        <Show
                          setRegion={props.setRegion}
                          key={result.id}
                          name={result.name}
                          img={result.poster_path}
                          overview={result.overview}
                          id={result.id}
                          genres={result.genre_ids}
                          first_air_date={result.first_air_date}
                          vote_average={result.vote_average}
                          vote_count={result.vote_count}
                          region={props.region}
                        />
                      )}
                    </Grid>
                  );
                })
            //NOT REALLY SURE HOW, BUT IT WORKS ?
          }
        </Grid>
      </div>
    </div>
  );
};

export default AdvancedSearch;
