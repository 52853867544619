import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { fetchRegion } from "../../../app/utils/utils";
import { fetchDefaultLanguage } from "./languageSlice";

export const regionSlice = createSlice({
  name: "region",
  initialState: {
    region: "",
    isLoading: false,
    hasError: false,
    loadingError: "",
  },
  reducers: {
    changeRegion: (state, action) => {
      state.region = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchUserRegion.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(fetchUserRegion.fulfilled, (state, action) => {
        state.isLoading = false;
        state.hasError = false;
        state.region = action.payload;
      })
      .addCase(fetchUserRegion.rejected, (state, action) => {
        state.isLoading = false;
        state.hasError = true;
        state.loadingError = action.payload;
      });
  },
});

export const fetchUserRegion = createAsyncThunk(
  "region/fetchUserRegion",
  async (_, ThunkAPI) => {
    const response = await fetchRegion();
    ThunkAPI.dispatch(fetchDefaultLanguage(response));
    return response;
  }
);

//Exporting actions and reducers
export const { changeRegion } = regionSlice.actions;
export default regionSlice.reducer;

export const selectRegion = (state) => state.region.region;
export const selectIsLoading = (state) => state.region.isLoading;

