import { configureStore } from "@reduxjs/toolkit";
import regionReducer from "./slices/regionSlice";
import languageReducer from "./slices/languageSlice";
import providerReducer from "./slices/providerSlice";
import discoverReducer from "./slices/discoverSlice";

export const store = configureStore({
  reducer: {
    region: regionReducer,
    language: languageReducer,
    providers: providerReducer,
    discover: discoverReducer,
  },
});
