import React, { useState } from "react";
import "./GenreSelectionButton.css";

const GenreSelectionButton = (props) => {
  const [active, setActive] = useState(false);

  const handleClick = (e) => {
    setActive((s) => !s);
    props.onClick(e);
  };

  return (
    <button
      id={props.id}
      key={props.id}
      className={`GenreSelectionButton-active-${active} GenreSelectionButton`}
      value={props.name}
      onClick={handleClick}
    >
      {props.name}{" "}
    </button>
  );
};

export default GenreSelectionButton;
