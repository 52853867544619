import React from "react";
import { regions } from "../../../app/utils/utils";
import "./RegionSelector.css";

const RegionSelector = (props) => {
  const { region } = props;

  return (
    <div className="RegionSelector">
      <h2>Select region</h2>
      <select
        value={region}
        name="Regions"
        id="region"
        className="RegionSelector-dropdown"
        onChange={(e) => {
          props.setRegion(e.target.value);
        }}
      >
        {regions.map((region) => {
          return (
            <option key={region.iso_3166_1} value={region.iso_3166_1}>
              {region.english_name}
            </option>
          );
        })}
      </select>
      <p>Current region: {region}</p>
    </div>
  );
};

export default RegionSelector;
